// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const base = 'https://admin-v2.preprod.kenza.re/rest/public/'

export const environment = {
    production: false,
    hmr       : false,
    api       : `${base}api/`,
    imgUrl    : `${base}api/get/media?path=`,
    siteUrl   : `https://preprod.kenza.re`,
    appName   : `KENZA`,
    map: {
        zoom: 10,
        lat : -21.1351121,
        lng: 55.2471124,
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/**
 * https://admin-v2.kenza.re/rest/public/api/stat/update/notifications
 * https://admin-v2.kenza.re/rest/public/api/devices/update/all
 * */
