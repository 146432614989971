import { Injectable } from '@angular/core';
import { User } from 'app/main/admin/users/users.model';
import { BehaviorSubject, Observable } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public currentUser: Observable<User>;

  constructor() {
    let data = localStorage.getItem('auth');
    if(data !== null){
      let user : User = JSON.parse(data)
      this.currentUserSubject = new BehaviorSubject<User>(user);
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }

  public get currentUserValue(): User {
    if(this.currentUserSubject){
      return this.currentUserSubject.value
    }else
     return
  }

  public setToken(user: User){    
    this.currentUserSubject.next(user);    
  }

  logout() {
      // remove user from local storage to log user out
      this.currentUserSubject.next(null);
  }
}