import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  session : BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private auth: AuthenticationService
  ) { 
    //this.getSession();
  }

  setSession(data: any):Promise<boolean>{
    return new Promise(async (resolve) => {
      this.auth.setToken(data)
      await localStorage.setItem('auth', JSON.stringify(data));
      resolve(true)
    })
    
  }

  getSession(){
    let data : any = localStorage.getItem('auth');
    if(data){
      data = JSON.parse(data)
    }
    this.session.next(data);
    return data;
  }

  removeSession():Promise<boolean>{
    return new Promise(async (resolve) => {
      await localStorage.removeItem('auth')
      this.session.next({});
      location.reload();
      resolve(true)
    })
  }

  sessionObservable(): Observable<any>{
    return this.session.asObservable()
  }
}
