import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { MatSnackBarModule } from '@angular/material'  
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from './layout/layout.module';

import { AppComponent } from 'app/app.component';
import { AccountGuard } from './guard/account.guard';

const routes: Routes = [    
    {
        path            : 'admin',
        loadChildren    : () => import('./main/admin/admin.module').then(m=> m.AdminModule),
        canActivateChild: [AccountGuard]      
    },
    {
        path            : 'login',
        loadChildren    : () => import('./main/auth/login/login.module').then(m=> m.LoginModule),
    },
    {
        path            : 'forgot-password',
        loadChildren    : () => import('./main/auth/forgot-password/forgot-password.module').then(m=> m.ForgotPasswordModule)
    },
    {
        path            : 'reset-password',
        loadChildren    : () => import('./main/auth/reset-password/reset-password.module').then(m=> m.ResetPasswordModule)
    },
    { 
        path: '**', 
        redirectTo: 'login', 
        pathMatch: 'full' 
    },
    { 
        path: '', 
        redirectTo: 'login', 
        pathMatch: 'full' 
    }, 
  
]

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(routes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatSnackBarModule,
        
        LayoutModule

    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        {
            provide: 'AccountGuard',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => true
        }
    ]
   
})
export class AppModule
{
}
