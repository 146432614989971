import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  constructor(
    private _httpClient: HttpClient,
    private _translateService: TranslateService
  ) { }

  public translate(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._translateService.get(key).subscribe(value => {
        resolve(value);
      }, reject);
    });
  } 

  private getTranslations(): Promise<Array<any>>{
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}bo/translation`)
        .subscribe((response: any) => { 
            resolve(response);
        }, reject);
    });
  }

  getLanguages(){
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.api}languages`)
        .subscribe((response: any) => { 
            resolve(response);
        }, reject);
    });
  }

  async init(){
    const translations = await this.getTranslations()
    if(translations.length > 0){
      for(let l of translations){
        if(l){        
          this._translateService.setTranslation(l.lang, l[l.lang]);
        }
      } 
      const currentLangId = localStorage.getItem('curent_lang_id');
      if(currentLangId) {
        // Set the default language
        this._translateService.setDefaultLang(currentLangId);

        // Use a language
        this._translateService.use(currentLangId);
      } else {
        this.setCurrentLangBrowser();
      }
    }else{
      this.setCurrentLangBrowser();
    }
  }

  private setCurrentLangBrowser() {
    const lang = this._translateService.getBrowserLang();
    // Set the default language
    this._translateService.setDefaultLang('fr');

    // Use a language
    this._translateService.use('fr');
  }
}
